var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(function () { return _vm.resizeTable('venues'); }),expression:"() => resizeTable('venues')"}],attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.$t('Venues'))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function ($event) {
                _vm.isSearchPanelOpen = !_vm.isSearchPanelOpen;
                _vm.resizeTable('venues');
                $event.target.blur();
              }}},on),[_vm._v(_vm._s(_vm.isSearchPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'))])]}}])},[_c('div',{staticClass:"filter-tooltip"},[_vm._v(" "+_vm._s(_vm.isSearchPanelOpen ? _vm.$t('Hide filter') : _vm.$t('Show filter'))+" ")])])],1),_c('v-flex',[_c('v-card-text',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchPanelOpen),expression:"isSearchPanelOpen"}],attrs:{"id":"searchPanel"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4","xl":"2"}},[_c('gli-text-field',{attrs:{"clearable":"","label":_vm.$t('Name'),"single-line":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4","xl":"2"}},[_c('gli-text-field',{attrs:{"clearable":"","label":_vm.$t('Description'),"single-line":""},model:{value:(_vm.filter.description),callback:function ($$v) {_vm.$set(_vm.filter, "description", $$v)},expression:"filter.description"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4","xl":"2"}},[_c('gli-text-field',{attrs:{"clearable":"","label":_vm.$t('Sites'),"single-line":""},model:{value:(_vm.filter.siteNames),callback:function ($$v) {_vm.$set(_vm.filter, "siteNames", $$v)},expression:"filter.siteNames"}})],1)],1)],1),_c('v-data-table',{attrs:{"id":"venues","mobile-breakpoint":0,"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"fixed-header":"","items":_vm.filteredVenues,"item-key":"id","loading":_vm.isLoading,"options":_vm.pagination,"custom-sort":_vm.customSort},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.description",fn:function(ref){
              var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.siteNames",fn:function(ref){
              var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.lastModificationTimestamp",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t(item.lastModificationTimestamp))+" "),(
                  _vm.modifiedAfterPublished(
                    item.lastModificationTimestamp,
                    item.lastPublishTimestamp
                  )
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',{staticClass:"ml-2"},[_c('v-icon',_vm._g({staticClass:"warn pb-1",attrs:{"dense":""}},on),[_vm._v("mdi-alert")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t('There are unpublished changes!'))+" ")])]):_vm._e()],1)]}},{key:"item.lastPublishTimestamp",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$i18n.t(item.lastPublishTimestamp))+" "),(
                  item.lastPublishTimestamp !== 'Not published' &&
                    item.lastWebhookResponse &&
                    item.lastWebhookResponse.statusCode !== 200
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',{staticClass:"ml-2"},[_c('v-icon',_vm._g({staticClass:"alert pb-1",attrs:{"dense":""}},on),[_vm._v("mdi-alert")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t('The last webhook response was:'))),_c('br'),_vm._v(" "+_vm._s(_vm.$i18n.t('Status: '))+" "+_vm._s(item.lastWebhookResponse.statusCode)),_c('br'),_vm._v(" "+_vm._s(item.lastWebhookResponse.message)+" ")])]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.publish(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-upload-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Publish')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":item.lastPublishTimestamp === 'Not published' ? undefined : 'primary',"fab":"","x-small":"","disabled":item.lastPublishTimestamp === 'Not published'},on:{"click":function($event){return _vm.unpublish(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Unpublish')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":item.lastPublishTimestamp === 'Not published' ? undefined : 'primary',"fab":"","x-small":"","disabled":item.lastPublishTimestamp === 'Not published'},on:{"click":function($event){return _vm.openPublished(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-compass")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Open published venue')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.edit(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteVenue(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])])]}}])})],1)],1),_c('v-card-actions',{staticClass:"mr-2"},[_c('v-spacer'),_c('gli-editor-button',{attrs:{"color":"primary"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('New venue')))])],1)],1),(_vm.editedVenue)?_c('venue-dialog'):_vm._e(),_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.publishing,"opacity":".5"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}}),_c('div',{staticClass:"mt-4"},[_c('b',[_vm._v(_vm._s(_vm.$t('Publishing venue...')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid v-resize="() => resizeTable('venues')">
    <v-card>
      <v-card-title class="pb-0">
        {{ $t('Venues') }}
        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="
                ($event) => {
                  isSearchPanelOpen = !isSearchPanelOpen;
                  resizeTable('venues');
                  $event.target.blur();
                }
              "
              >{{ isSearchPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
            >
          </template>
          <div class="filter-tooltip">
            {{ isSearchPanelOpen ? $t('Hide filter') : $t('Show filter') }}
          </div>
        </v-tooltip>
      </v-card-title>
      <v-flex>
        <v-card-text>
          <div id="searchPanel" v-show="isSearchPanelOpen">
            <v-row>
              <v-col cols="4" md="4" xl="2"
                ><gli-text-field
                  clearable
                  v-model="filter.name"
                  :label="$t('Name')"
                  single-line
                ></gli-text-field
              ></v-col>
              <v-col cols="4" md="4" xl="2"
                ><gli-text-field
                  clearable
                  v-model="filter.description"
                  :label="$t('Description')"
                  single-line
                ></gli-text-field
              ></v-col>
              <v-col cols="4" md="4" xl="2"
                ><gli-text-field
                  clearable
                  v-model="filter.siteNames"
                  :label="$t('Sites')"
                  single-line
                ></gli-text-field
              ></v-col>
            </v-row>
          </div>

          <v-data-table
            id="venues"
            :mobile-breakpoint="0"
            :headers="headers.map((item) => ({ ...item, text: $t(item.text) }))"
            fixed-header
            :items="filteredVenues"
            item-key="id"
            :loading="isLoading"
            :options.sync="pagination"
            :custom-sort="customSort"
          >
            <template v-slot:item.name="{ value }">
              <span>{{ value }}</span>
            </template>
            <template v-slot:item.description="{ value }">
              <span>{{ value }}</span>
            </template>
            <template v-slot:item.siteNames="{ value }">
              <span>{{ value }}</span>
            </template>
            <template v-slot:item.lastModificationTimestamp="{ item }">
              <span>
                {{ $i18n.t(item.lastModificationTimestamp) }}
                <v-tooltip
                  bottom
                  v-if="
                    modifiedAfterPublished(
                      item.lastModificationTimestamp,
                      item.lastPublishTimestamp
                    )
                  "
                >
                  <template v-slot:activator="{ on }">
                    <span class="ml-2">
                      <v-icon v-on="on" class="warn pb-1" dense>mdi-alert</v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $i18n.t('There are unpublished changes!') }}
                  </span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:item.lastPublishTimestamp="{ item }">
              <span
                >{{ $i18n.t(item.lastPublishTimestamp) }}
                <v-tooltip
                  bottom
                  v-if="
                    item.lastPublishTimestamp !== 'Not published' &&
                      item.lastWebhookResponse &&
                      item.lastWebhookResponse.statusCode !== 200
                  "
                >
                  <template v-slot:activator="{ on }">
                    <span class="ml-2">
                      <v-icon v-on="on" class="alert pb-1" dense>mdi-alert</v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $i18n.t('The last webhook response was:') }}<br />
                    {{ $i18n.t('Status: ') }}
                    {{ item.lastWebhookResponse.statusCode }}<br />
                    {{ item.lastWebhookResponse.message }}
                  </span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    color="primary"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="publish(item.id)"
                  >
                    <v-icon>mdi-cloud-upload-outline</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Publish') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    :color="item.lastPublishTimestamp === 'Not published' ? undefined : 'primary'"
                    fab
                    x-small
                    :disabled="item.lastPublishTimestamp === 'Not published'"
                    v-on="on"
                    @click="unpublish(item.id)"
                  >
                    <v-icon>mdi-cloud-off-outline</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Unpublish') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    :color="item.lastPublishTimestamp === 'Not published' ? undefined : 'primary'"
                    fab
                    x-small
                    :disabled="item.lastPublishTimestamp === 'Not published'"
                    v-on="on"
                    @click="openPublished(item.id)"
                  >
                    <v-icon>mdi-compass</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Open published venue') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    color="primary"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="edit(item.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Edit') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    color="error"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="deleteVenue(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-flex>

      <v-card-actions class="mr-2">
        <v-spacer></v-spacer>
        <gli-editor-button color="primary" @click="addNew">{{ $t('New venue') }}</gli-editor-button>
      </v-card-actions>
    </v-card>

    <venue-dialog v-if="editedVenue"></venue-dialog>
    <v-overlay class="text-center" :value="publishing" opacity=".5">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      <div class="mt-4">
        <b>{{ $t('Publishing venue...') }}</b>
      </div>
    </v-overlay>
  </v-container>
</template>

<style lang="scss" scoped>
::v-deep .row-error {
  color: var(--v-error-base);
}

.select-filter.v-icon:focus::after {
  opacity: 0 !important;
}

.alert {
  color: var(--v-error-darken1);
}

.warn {
  color: var(--v-warning-darken1);
}

#searchPanel {
  padding: 10px;
  ::v-deep .v-label {
    font-size: 0.9em;
  }
  .col-4,
  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.v-application .v-data-table .v-data-table__expanded .v-data-table.table-details {
  background: var(--v-background-darken1);
  padding: 6px;
  border-radius: 0;
}
</style>

<script>
import i18n from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
import resizeTable from '../mixins/resizeTable';
import config from '../helpers/configProvider';
import moment from 'moment-mini';

const emptyFilter = {
  name: '',
  description: '',
  siteNames: ''
};

export default {
  name: 'Venues',

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Sites',
          value: 'siteNames'
        },
        {
          text: 'Last modified',
          value: 'lastModificationTimestamp'
        },
        {
          text: 'Last published',
          value: 'lastPublishTimestamp'
        },
        {
          text: 'Identifier',
          value: 'id',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          width: 232,
          sortable: false,
          align: 'end'
        }
      ],
      isSearchPanelOpen: true,
      filter: JSON.parse(JSON.stringify(emptyFilter)),

      pagination: {
        page: 1
      }
    };
  },

  async mounted() {
    await this.getAll();
  },

  async beforeDestroy() {},

  computed: {
    ...mapGetters('venue', ['venues', 'isLoading', 'editedVenue', 'publishing']),

    filteredVenues() {
      let filtered = this.venues.map((venue) => ({
        ...venue,
        siteNames: venue.sites.map((site) => site.name).join(', '),
        lastPublishTimestamp: venue.lastPublishTimestamp
          ? new Date(venue.lastPublishTimestamp).toLocaleString(
              i18n.locale === 'hu' ? 'hu-HU' : 'en-GB'
            )
          : 'Not published',
        lastModificationTimestamp: new Date(venue.lastModificationTimestamp).toLocaleString(
          i18n.locale === 'hu' ? 'hu-HU' : 'en-GB'
        )
      }));

      for (const [key, value] of Object.entries(this.filter)) {
        if (!value) {
          continue;
        }

        // v-select filter
        if (Array.isArray(value)) {
          if (value.length) {
            filtered = filtered.filter((freight) => value.includes(freight[key]));
          }
        } else {
          // gli-text-field filter
          const regexp = new RegExp(value, 'i');
          filtered = filtered.filter((item) => item[key].match(regexp));
        }
      }

      return filtered;
    }
  },

  methods: {
    ...mapActions('alert', ['error']),
    ...mapActions('venue', ['getAll', 'addNew', 'edit', 'publish', 'unpublish']),
    ...mapActions('venue', { deleteVenue: 'delete' }),

    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        let valueA = a[index[0]];
        let valueB = b[index[0]];

        // transform empty values to comparable forms
        if ([undefined, null, 'Not published'].includes(valueA)) {
          valueA = '';
        }
        if ([undefined, null, 'Not published'].includes(valueB)) {
          valueB = '';
        }

        if (['lastModificationTimestamp', 'lastPublishTimestamp'].includes(index[0])) {
          // compare date fields
          const firstDate = moment(
            valueA,
            i18n.locale === 'hu' ? 'YYYY. MM. DD. hh:mm:ss' : 'DD/MM/YYYY hh:mm:ss'
          );
          const secondDate = moment(
            valueB,
            i18n.locale === 'hu' ? 'YYYY. MM. DD. hh:mm:ss' : 'DD/MM/YYYY hh:mm:ss'
          );

          if (!firstDate._isValid || !secondDate._isValid) {
            // if any of them is an invalid date
            return (isDescending[0] && firstDate._isValid) ||
              (!isDescending[0] && secondDate._isValid)
              ? 1
              : -1;
          } else {
            return isDescending[0] ? firstDate - secondDate : secondDate - firstDate;
          }
        } else {
          // compare other fields
          if (isDescending[0]) {
            return valueB < valueA ? -1 : 1;
          } else {
            return valueA < valueB ? -1 : 1;
          }
        }
      });

      return items;
    },

    openPublished(id) {
      const url = `${config.getLDMTileServiceUrl()}${id}`;
      window.open(url);
    },

    modifiedAfterPublished(modified, published) {
      const modifiedDate = moment(
        modified,
        i18n.locale === 'hu' ? 'YYYY. MM. DD. hh:mm:ss' : 'DD/MM/YYYY hh:mm:ss'
      );
      const publishedDate = moment(
        published,
        i18n.locale === 'hu' ? 'YYYY. MM. DD. hh:mm:ss' : 'DD/MM/YYYY hh:mm:ss'
      );
      return modifiedDate > publishedDate;
    }
  },

  watch: {
    filter: {
      handler() {
        this.pagination.page = 1;
      },
      deep: true
    }
  },

  mixins: [resizeTable],

  components: {
    VenueDialog: () => import('../components/VenueDialog')
  }
};
</script>
